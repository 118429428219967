import React from "react";
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { ToWords } from 'to-words';

const toWords = new ToWords({
  localeCode: 'en-IN', // Indian numbering format
  converterOptions: {
    currency: false,
    ignoreDecimal: true,
  }
});

const DoInputField = (props) => {
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : '';
    const name = props.name;
    const type = props.type ? props.type : 'text';
    let field = props.field;
    const placeholder = props.placeholder;
    const style = props.style ? props.style : null;
    const limit = 1e10; // 100 crore limit

    let markRequired = () => {
        return (
          <FontAwesomeIcon
            color='red'
            icon={faAsterisk}
            style={{ width: '7px',marginBottom:"5px",marginLeft:"2px" }}
          />
        );
      };

  const onChange = (e) => {
    let value = e.target.value;

    // Restrict the value to 100 crore
    if (props.displayPrice && !isNaN(value) && parseFloat(value) <= limit) {
      field.onChange(value);
    } else if (!props.displayPrice){
      field.onChange(value);
    } else if (value === '') {
      field.onChange(value);
    }
    if (props.onChange) {
      props.onChange(e);
    } 
  };

  const parsedNumber = field.value ? parseFloat(field.value) : NaN;

    return (
        <div className="flex flex-column">
             <div className="flex align-center"> {/* Added a wrapper div with flex class */}
                <label htmlFor={id} className="text-capitalize">{label}</label>
                {props.markReq && props.markReq === true && markRequired()}
            </div>
            <InputText 
            type={type}
            onKeyDown={(e) => {
              // Check if the type is 'number' before preventing the 'e' key
              if (type === 'number' && (e.key === 'e' || e.key === 'E')) {
                e.preventDefault();
              }
            }} 
            value={field.value}
            id={id} 
            name={name} 
            placeholder={placeholder}
            aria-describedby="username-help" 
            style={style}
            onChange={onChange} /> 
        <small className="text-danger mt-1">{props.fieldState.invalid ? props.errors[props.name]?.message : ''}</small>
        <span>
          {props.displayPrice && !isNaN(parsedNumber) && parsedNumber <= limit && parsedNumber > 0 && (
            <span className="number_to_words">
              {toWords.convert(parsedNumber)} {/* Convert number to words in Indian format */}
            </span>
          )}
        </span>    
        </div>
    )
}

export default DoInputField;