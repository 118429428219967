import React, { PureComponent, useEffect, useState } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import { Link, NavLink } from 'react-router-dom';
import TopbarMenuLink from './TopbarMenuLink';
import config from '../../../config/config';
import configImages from '../../../config/configImages';
const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;
const noProfileImage = configImages.defaultImg;
import menuItem from '../MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);


const TopbarProfile = (props) => {
  
  const [collapse, setCollapse] = useState(false);
  const [userData, setUserData] = useState({});
  const [menuList, setMenuList] = useState([]);
  let navBarItems = ['/savedSearches', '/recentMatchedProperties', "/agents", "/offices", "/tickets", "/reminders", "/tickets", "/activities", "/toDoLists"];

  const toggle = () => {    
    setCollapse((prevState) => {return !prevState});
  };
  
  useEffect(() => {
    updateTopbarProfileData();
    fetchMenuItems();
    return () => {

    };
  }, []);

  const fetchMenuItems = async () => {
    const menuItems = await menuItem.getMenuListFromServer();
    setMenuList(menuItems);
  };

  const updateTopbarProfileData = async () => {
    let locUserData = await localStorage.getItem('loginCredentials');
    if(locUserData){
      setUserData(JSON.parse(locUserData));
    }   
  }
  
  const profileImage = (userData && userData.photo) ? userData.photo[0] : null;
  const capitalize = (str) => str?.charAt(0).toUpperCase() + str.slice(1); //for captilizing the 1st letter of firstname and lastname

  return (
    <div className="topbar__profile">
      <button className="topbar__avatar" type="button" onClick={toggle}>
        {/* change */}
        <img className="topbar__avatar-img"
          src={profileImage ? `${config.profileUrl}${profileImage}` : noProfileImage} />
        <p className="topbar__avatar-name">
          {(userData?.firstName && userData?.lastName) ?
            `${capitalize(userData.firstName)} ${capitalize(userData.lastName)}` :
            userData?.firstName ? `${capitalize(userData.firstName)}`
            : 'No Name'}
        </p>
        <DownIcon className="topbar__icon" />
      </button>
      {collapse && <button className="topbar__back" type="button" onClick={toggle} />}
      <Collapse isOpen={collapse} className="topbar__menu-wrap">
        <div className="topbar__menu topbarMenu">
          <div className='topBarImageAlignment'>
            <img src={profileImage ? `${config.profileUrl}${profileImage}` : noProfileImage}
              className='topBarImage' />
            <div>
              {(userData?.firstName && userData?.lastName) ?
                `${capitalize(userData.firstName)} ${capitalize(userData.lastName)}` :
                userData?.firstName ? `${capitalize(userData.firstName)}`
                  : 'No Name'}
              {/* <div className='themeColorText'>{userData &&
                userData.role ?
                userData.role : ''}</div> */}
            </div>
          </div>

          <div className="topbar__menu-divider" />
          <div className="topbar__menu">
            <div className='d-none d-md-block'>
              {menuList && menuList.length > 0 && menuList.map((item, index) => {
                if (navBarItems.some(navItem => item.route.includes(navItem))) {
                  return (
                    <NavLink
                      to={item.route}
                      activeClassName="activeTopBar"
                      className="topbar__link"
                      key={index}
                      onClick={toggle}
                    >
                      {item.iconType === 'icon' && (
                        <span style={{ marginRight: '8px' }}>
                          <FontAwesomeIcon icon={['fas', menuItem.getIcon(item)]} />
                        </span>
                      )}
                      {item.displayTitle}
                    </NavLink>
                  );
                }
              })}
              <div className="topbar__menu-divider" />
            </div>

            <div>
              <Link className="topbar__link"
                to={{ pathname: `/profile`, state: { updateTopbarProfileData: props.updateTopbarProfileData } }}
                onClick={toggle}>
                <span className={`topbar__link-icon lnr lnr-user`} />
                <p className="topbar__link-title">{"My Profile"}</p>
              </Link>
            </div>

            <div>
              <Link className="topbar__link"
                to={{ pathname: `/changePassword`, state: { updateTopbarProfileData: props.updateTopbarProfileData } }}
                onClick={toggle}>
                <span className={`topbar__link-icon lnr lnr-bubble`} />
                <p className="topbar__link-title">{"Change Password"}</p>
              </Link>
            </div>
            {
              config.displaySettings ?
                <div >
                  <Link className="topbar__link"
                    to={{ pathname: `/adminSettings`, state: { updateTopbarProfileData: props.updateTopbarProfileData } }}
                    onClick={toggle}>
                    <span className={`topbar__link-icon lnr lnr-cog`} />
                    <p className="topbar__link-title">{"Settings"}</p>
                  </Link>
                </div> : null
            }
            <div className="topbar__menu-divider" />
            <TopbarMenuLink title="Log Out" icon="exit" path="/log_in" toggleClose={toggle}
              updateTopbarProfileData={updateTopbarProfileData}
            />
          </div>
        </div>
      </Collapse>
    </div >
  );
}
export default TopbarProfile;
